import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import ApiHeader from "../Common/ApiHeader";
import ErrorHandler from "../Common/ErrorHandler";

const PopupWelcome = ({ club_nick, crewId }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [hidePopup, setHidePopup] = useState(false);

  useEffect(() => {
    ApiHeader.get(`/api/v1/crews/${crewId}`)
      .then((response) => {
        if (response.data.updated_time === null) {
          setDialogOpen(true);
        }
      })
      .catch((e) => {
        ErrorHandler(e, "PopupWelcome.js, useEffect");
      });
  }, [club_nick, crewId]);

  const handleClose = () => {
    if (hidePopup) {
      ApiHeader.put(`/api/v1/crews/${crewId}/state/1`)
        .then(() => {})
        .catch((e) => {
          ErrorHandler(e, "PopupWelcome.js, handleClose");
        });
    }
    setDialogOpen(false);
  };

  const handleCheckboxChange = (event) => {
    setHidePopup(event.target.checked);
  };

  return (
    <Dialog open={dialogOpen} onClose={handleClose}>
      <DialogTitle>환영합니다!</DialogTitle>
      <DialogContent>
        <Typography sx={{ mb: 2 }}>
          {club_nick.toUpperCase()}에 오신 걸 환영합니다.
        </Typography>

        {club_nick.toUpperCase() === "YRC" ? (
          <>
            <Typography sx={{ mb: 2 }}>
              가입하신 분들 모두 아래 공지방 클릭 후 입장 부탁드립니다. <br />
              참여코드는 <strong>yrc1004</strong> 입니다.
            </Typography>
            <Typography sx={{ mb: 2 }}>
              <a
                href="https://open.kakao.com/o/gvOlNToc"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#3f50b5", textDecoration: "inherit" }}
              >
                <strong>카카오톡 오픈채팅 YRC 공지방</strong>
              </a>
            </Typography>
          </>
        ) : (
          <Link
            to={`/${club_nick}/notices`}
            style={{ color: "#3f50b5", textDecoration: "inherit" }}
          >
            <Typography sx={{ mb: 2 }}>
              규정 및 공지사항 확인 부탁드립니다.
            </Typography>
          </Link>
        )}

        <FormControlLabel
          control={
            <Checkbox checked={hidePopup} onChange={handleCheckboxChange} />
          }
          label="이 창을 숨기기"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          닫기
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PopupWelcome;
