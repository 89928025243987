import React, { useEffect, useState, useRef } from "react";
import {
  FormControl,
  FormLabel,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
} from "@mui/material";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import useCrewData from "../Common/CrewData";
import ApiHeader from "../Common/ApiHeader";
import conf from "../../config/conf_sql.json";
import Hashids from "hashids";
import ErrorHandler from "../Common/ErrorHandler";
import ImageUpload from "../Image/ImageUpload";

const hashids = new Hashids(conf.secret, 10);

// 카테고리 선택 컴포넌트
const CategorySelect = ({ categories, value, onChange, adminLevel }) => {
  const filtered = adminLevel >= 7 ? categories : categories.slice(2);
  return (
    <FormControl fullWidth size="small">
      <InputLabel id="category-label" sx={{ fontSize: 16 }}>
        Category
      </InputLabel>
      <Select
        labelId="category-label"
        value={value}
        label="Category"
        onChange={(e) => onChange(e.target.value)}
      >
        {filtered.map((c) => (
          <MenuItem key={c} value={c}>
            {c}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

function NoticeCreate() {
  const { club_nick, notice_id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { memberId, clubId, crewId, adminLevel } = useCrewData(club_nick);

  const {
    notice_category = "",
    notice_title = "",
    notice_text = "",
    notice_image = "",
    type = "",
    timeAuthorized = 0,
  } = location.state || {};

  const [vCategory, setCategory] = useState(notice_category);
  const [vTitle, setTitle] = useState(notice_title);
  const [vText, setText] = useState(notice_text);
  const [vImage, setImage] = useState(notice_image);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dialog, setDialog] = useState({
    open: false,
    message: "",
    confirm: false,
  });

  const [clubSetting, setClubSetting] = useState({});
  const [loading, setLoading] = useState(true);
  const ImageUploadRef = useRef();

  useEffect(() => {
    ApiHeader.get(`/api/v1/clubs/nick/${club_nick}`)
      .then((response) => {
        setClubSetting(JSON.parse(response.data.setting) || {});
      })
      .catch((e) => {
        ErrorHandler(e, "NoticeCreate.js, get club setting");
      })
      .finally(() => setLoading(false));
  }, [club_nick]);

  const categories = [
    "규정",
    "공지",
    ...(clubSetting.bulletinlist
      ? clubSetting.bulletinlist
          .split(",")
          .filter((v) => v && v !== "규정" && v !== "공지")
      : []),
  ];

  // ❗️Select 유효성 검사 적용
  const validCategory = categories.includes(vCategory) ? vCategory : "";

  useEffect(() => {
    setIsAuthorized(Date.now() - timeAuthorized <= 10000);
  }, [timeAuthorized]);

  const handleSubmit = () => {
    if (vCategory && vTitle && vText) {
      setDialog({
        open: true,
        message: notice_id
          ? "게시 수정 하시겠습니까?"
          : "게시 등록 하시겠습니까?",
        confirm: true,
      });
    } else {
      setDialog({
        open: true,
        message: "모두 작성해 주시기 바랍니다.",
        confirm: false,
      });
    }
  };

  const handleSave = async (uploadedIds) => {
    const decodedClubId = hashids.decode(clubId)[0];
    const decodedCrewId = hashids.decode(crewId)[0];

    if (notice_id) {
      await ApiHeader.put(`/api/v1/notices/${notice_id}`, {
        notice_category: vCategory,
        notice_title: vTitle,
        notice_text: vText,
      });
      navigate(`/${club_nick}/notice/${notice_id}`);
    } else {
      const { data } = await ApiHeader.get("/api/v1/notices/max_id");
      const newId = data.max_id + 1;
      await ApiHeader.post("/api/v1/notices", {
        id: newId,
        crew_id: decodedCrewId,
        club_id: decodedClubId,
        notice_category: vCategory,
        notice_title: vTitle,
        notice_text: vText,
        notice_image: uploadedIds,
      });
      navigate(`/${club_nick}/notice/${hashids.encode(newId)}`);
    }
  };

  const handleAgree = async () => {
    if (dialog.confirm && !isSubmitting) {
      setIsSubmitting(true);
      try {
        if (!notice_id) {
          await ImageUploadRef.current?.uploadFiles();
        } else {
          await handleSave(undefined);
        }
      } catch (e) {
        ErrorHandler(e, "NoticeCreate.js > handleAgree");
      } finally {
        setIsSubmitting(false);
        setDialog({ ...dialog, open: false });
      }
    } else {
      setDialog({ ...dialog, open: false });
    }
  };

  const handleUpload = async (uploadedIds) => {
    if (uploadedIds?.length) setImage(uploadedIds);
    await handleSave(uploadedIds);
  };

  if (!isAuthorized) return <div>잘못된 접근입니다.</div>;

  return (
    <Box sx={{ maxWidth: 800, margin: 1 }}>
      <FormControl fullWidth>
        <FormLabel sx={{ fontSize: 32, mb: 2 }}>
          {notice_id ? "게시 수정" : "게시 등록"}
        </FormLabel>

        <FormLabel sx={{ fontSize: 16, mb: 0.5 }}>카테고리</FormLabel>
        <CategorySelect
          categories={categories}
          value={validCategory}
          onChange={setCategory}
          adminLevel={adminLevel}
        />

        <FormLabel sx={{ fontSize: 16, mt: 2, mb: 0.5 }}>제목</FormLabel>
        <TextField
          required
          label="Title"
          value={vTitle}
          onChange={(e) => setTitle(e.target.value)}
          size="small"
        />

        <FormLabel sx={{ fontSize: 16, mt: 2, mb: 0.5 }}>내용</FormLabel>
        <TextField
          required
          label="Text"
          value={vText}
          onChange={(e) => setText(e.target.value)}
          size="small"
          multiline
        />

        {!notice_id && (
          <ImageUpload
            ref={ImageUploadRef}
            club_nick={club_nick}
            handleUpload={handleUpload}
          />
        )}

        <Button
          variant="contained"
          onClick={handleSubmit}
          sx={{ mt: 2 }}
          disabled={isSubmitting}
        >
          {notice_id ? "수정" : "등록"}
        </Button>

        <Dialog
          open={dialog.open}
          onClose={() => setDialog({ ...dialog, open: false })}
        >
          <DialogTitle>
            {notice_id ? "게시 수정 확인" : "게시 등록 확인"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>{dialog.message}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialog({ ...dialog, open: false })}>
              취소
            </Button>
            <Button onClick={handleAgree} autoFocus disabled={isSubmitting}>
              확인
            </Button>
          </DialogActions>
        </Dialog>
      </FormControl>
    </Box>
  );
}

export default NoticeCreate;
