import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import { AppBar, Toolbar, Box } from "@mui/material";
import TopRight from "./TopRight";
import TopBottom from "./TopBottom";
import useCrewData from "../Common/CrewData";
import NoShowWarning from "./NoShowWarning";
import PopupInDay from "./PopupInDay";
import PopupInHour from "./PopupInHour";
import PopupWelcome from "./PopupWelcome";
import LastMinuteCancelWarning from "./LastMinuteCancelWarning";
import conf from "../../config/conf_sql.json";
import ApiHeader from "../Common/ApiHeader";
import ErrorHandler from "../Common/ErrorHandler";

function TopAppBar({ club_nick }) {
  const { memberId, clubId, crewId, adminLevel } = useCrewData(club_nick);
  const [name, setName] = useState("");
  const [nick, setNick] = useState(undefined);
  const [setting, setSetting] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    ApiHeader.get(`/api/v1/clubs/list`)
      .then(({ data }) => {
        const foundClub = data.find(
          (club) => club.nick.toUpperCase() === club_nick?.toUpperCase()
        );
        setName(foundClub?.name || "");
        setNick(foundClub?.nick || undefined);
        setSetting(JSON.parse(foundClub?.setting) || {});
      })
      .catch((e) => {
        ErrorHandler(e, "TopAppBar.js, get club data");
      })
      .finally(() => setLoading(false));
  }, [club_nick]);

  return (
    !loading && (
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="fixed"
          sx={{
            maxWidth: "800px",
            height: "55px",
            margin: "auto",
            left: 0,
            right: 0,
            bgcolor: setting.color,
            zIndex: (theme) => theme.zIndex.drawer + 2,
          }}
        >
          <Toolbar>
            {nick ? (
              <>
                <Link
                  to={`/${nick}/`}
                  style={{ color: "inherit", textDecoration: "inherit" }}
                >
                  <img
                    key="img_logo"
                    className="img"
                    alt={nick}
                    src={conf.media + setting.icon_url}
                    width={35}
                  />
                </Link>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  &nbsp;{name}
                </Typography>
              </>
            ) : (
              <>
                <Link
                  to="/"
                  style={{ color: "inherit", textDecoration: "inherit" }}
                >
                  <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    &nbsp;{"CrewWITH"}
                  </Typography>
                </Link>
              </>
            )}
            <Box sx={{ flexGrow: 1 }} />
            <TopRight logout={memberId === ""} club_nick={nick} />
          </Toolbar>
        </AppBar>
        <Toolbar />
        {nick && (
          <>
            <Box
              sx={{
                position: "fixed",
                top: "56px",
                width: "100%",
                zIndex: (theme) => theme.zIndex.drawer + 1,
                bgcolor: "background.paper",
              }}
            >
              <TopBottom
                memberId={memberId}
                clubId={clubId}
                crewId={crewId}
                adminLevel={adminLevel}
                club_nick={nick}
              />
            </Box>
            {crewId && (
              <Box sx={{ mt: "40px" }}>
                {" "}
                <NoShowWarning club_nick={nick} crewId={crewId} />
                <LastMinuteCancelWarning club_nick={nick} crewId={crewId} />
                <PopupInDay club_nick={nick} crewId={crewId} />
                <PopupInHour club_nick={nick} crewId={crewId} />
                <PopupWelcome club_nick={nick} crewId={crewId} />
              </Box>
            )}
          </>
        )}
      </Box>
    )
  );
}

export default TopAppBar;
