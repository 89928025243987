import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import conf from "../../config/conf_sql.json";
import ApiHeader from "../Common/ApiHeader";
import ErrorHandler from "../Common/ErrorHandler";
import Hashids from "hashids";
const hashids = new Hashids(conf.secret, 10);

function CrewInfo({ api, adminLevel, club_nick }) {
  const [crewData, setCrewData] = useState([]);
  const [infoData, setInfoData] = useState({});
  const [clubLevels, setClubLevels] = useState({});
  const [selectedLevel, setSelectedLevel] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [newLevel, setNewLevel] = useState("");
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    ApiHeader.get(api)
      .then((response) => {
        setIsValid(true);
        setCrewData(response.data);
        setInfoData(JSON.parse(response.data.info));
        setSelectedLevel(response.data.admin_level);
      })
      .catch((e) => {
        ErrorHandler(e, "CrewInfo.js, useEffect, get crew data");
      });
  }, [api]);

  useEffect(() => {
    ApiHeader.get(`/api/v1/clubs/nick/${club_nick}`)
      .then((response) => {
        const levels = response.data.levels && JSON.parse(response.data.levels);
        setClubLevels({ "-0": "탈퇴", "-1": "강제탈퇴", ...levels });
      })
      .catch((e) => {
        ErrorHandler(e, "CrewInfo.js, useEffect, get club levels");
      });
  }, [club_nick]);

  const handleLevelChange = (event) => {
    setNewLevel(event.target.value);
    setDialogOpen(true);
  };

  const handleDialogClose = (confirm) => {
    if (confirm) {
      setSelectedLevel(newLevel);

      ApiHeader.put(
        `/api/v1/crews/${hashids.encode(crewData.id)}/admin/${newLevel}`
      )
        .then((response) => {})
        .catch((e) => {
          ErrorHandler(e, "CrewInfo.js, handleLevelChange, put crew data");
        });

      if (newLevel === "-0" || newLevel === "-1") {
        ApiHeader.put(
          `/api/v1/crews/${hashids.encode(crewData.id)}/state/${newLevel}`
        )
          .then((response) => {})
          .catch((e) => {
            ErrorHandler(e, "CrewInfo.js, handleStateChange, put ban");
          });

        ApiHeader.delete(
          `/api/v1/attendees/crew/${hashids.encode(crewData.id)}`
        )
          .then((response) => {})
          .catch((e) => {
            ErrorHandler(e, "CrewInfo.js, delete crew, crew attendee");
          });

        window.history.back();
      }
    }
    setDialogOpen(false);
  };

  return (
    <div>
      {isValid && adminLevel > 0 ? (
        <>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell sx={{ minWidth: 70 }}>이름</TableCell>
                <TableCell>
                  {crewData.nick} (
                  {adminLevel >= 7 && crewData.nick !== crewData.name
                    ? crewData.name + ", "
                    : ""}
                  {crewData.gender === "Male"
                    ? "남"
                    : crewData.gender === "Female"
                    ? "여"
                    : ""}
                  , {crewData.nationality})
                </TableCell>
              </TableRow>
              {adminLevel >= 7 && (
                <TableRow>
                  <TableCell>생일</TableCell>
                  <TableCell>
                    {moment(crewData.birthday).format("Y년 M월 D일")}
                  </TableCell>
                </TableRow>
              )}
              {adminLevel >= 7 && (
                <TableRow>
                  <TableCell>사는곳</TableCell>
                  <TableCell>{crewData.address}</TableCell>
                </TableRow>
              )}
              {adminLevel >= 8 && (
                <TableRow>
                  <TableCell>이메일</TableCell>
                  <TableCell>{crewData.email}</TableCell>
                </TableRow>
              )}
              {adminLevel >= 8 && (
                <TableRow>
                  <TableCell>연락처</TableCell>
                  <TableCell>{crewData.contact}</TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell>가입일</TableCell>
                <TableCell>
                  {moment(crewData.created_time)
                    .subtract(conf.time_offset)
                    .format("Y년 M월 D일")}
                </TableCell>
              </TableRow>
              {Object.entries(infoData).map(([key, value]) => (
                <TableRow key={key}>
                  <TableCell>{key}</TableCell>
                  <TableCell style={{ whiteSpace: "pre-wrap" }}>
                    {value}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell>크루 등급</TableCell>
                <TableCell>
                  {adminLevel >= 8 && crewData.admin_level < adminLevel ? (
                    <Select
                      value={selectedLevel}
                      onChange={handleLevelChange}
                      size="small"
                    >
                      {Object.entries(clubLevels)
                        .filter(
                          ([level, label]) => level < adminLevel && label !== ""
                        )
                        .map(([level, label]) => (
                          <MenuItem
                            key={level}
                            value={level}
                            style={{
                              color:
                                level <= 0
                                  ? "red"
                                  : level >= 7
                                  ? "blue"
                                  : "inherit",
                            }}
                          >
                            {label}
                          </MenuItem>
                        ))}
                    </Select>
                  ) : (
                    clubLevels[crewData.admin_level]
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Dialog open={dialogOpen} onClose={() => handleDialogClose(false)}>
            <DialogTitle>
              {newLevel === "-1"
                ? "강제 탈퇴 확인"
                : newLevel === "-0"
                ? "탈퇴 확인"
                : "등급 변경 확인"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {newLevel === "-1"
                  ? "이 회원을 강제탈퇴시키겠습니까?"
                  : newLevel === "-0"
                  ? "이 회원을 탈퇴시키겠습니까?"
                  : "이 회원의 등급을 변경하시겠습니까?"}{" "}
                <br />
                {clubLevels[selectedLevel]} → {clubLevels[newLevel]}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleDialogClose(false)} color="primary">
                취소
              </Button>
              <Button
                onClick={() => handleDialogClose(true)}
                color="primary"
                autoFocus
              >
                확인
              </Button>
            </DialogActions>
          </Dialog>
        </>
      ) : isValid ? (
        <div>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography variant="h6" color="error">
              유효하지 않은 회원입니다.
            </Typography>
          </Box>
        </div>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ height: "50vh" }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
}

export default CrewInfo;
