import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import "./App.css";
import Main from "./components/Main/Main";
import Activities from "./components/Activity/Activities";
import Activity from "./components/Activity/Activity";
import Crews from "./components/Crew/Crews";
import Crew from "./components/Crew/Crew";
import NotFound from "./components/NotFound";
import KakaoLogin from "./components/Login/KakaoLogin";
import KakaoLogout from "./components/Login/KakaoLogout";
import Footer from "./components/Main/Footer";
import SignUp from "./components/Login/SignUp";
import ActivityCreate from "./components/Activity/ActivityCreate";
import ClubSignUp from "./components/Club/ClubSignUp";
import ClubMain from "./components/Club/ClubMain";
import Profile from "./components/Main/Profile";
import ClubCreate from "./components/Club/ClubCreate";
import ClubInfo from "./components/Club/ClubInfo";
import Notices from "./components/Notice/Notices";
import Notice from "./components/Notice/Notice";
import NoticeCreate from "./components/Notice/NoticeCreate";
import Bulletin from "./components/Notice/Bulletin";
import TermsOfService from "./components/Terms/TermsOfService";
import PrivacyPolicy from "./components/Terms/PrivacyPolicy";
import CrewWITH from "./components/Main/CrewWITH";
import TopAppBar from "./components/Main/TopAppBar";

function AppContent() {
  const location = useLocation();
  const club_nick = location.pathname.split("/")[1] || undefined;

  const preventRightClick = (e) => {
    e.preventDefault();
  };

  const pathsAllowingRightClickAndSelect = [
    "/create",
    "/modify",
    "/notice/",
    "/activity/",
    "/crew/",
  ];

  const allowRightClickAndSelect = pathsAllowingRightClickAndSelect.some(
    (path) => location.pathname.includes(path)
  );

  return (
    <div
      className={`App ${!allowRightClickAndSelect ? "no-select" : ""}`}
      onContextMenu={allowRightClickAndSelect ? null : preventRightClick}
    >
      <TopAppBar club_nick={club_nick} />
      <Routes>
        <Route path="/" element={<Main />}></Route>
        <Route path="/crewwith" element={<CrewWITH />}></Route>
        <Route path="/privacypolicy" element={<PrivacyPolicy />}></Route>
        <Route path="/termsofservice" element={<TermsOfService />}></Route>
        <Route path="/oauth" element={<KakaoLogin />}></Route>
        <Route path="/logout" element={<KakaoLogout />}></Route>
        <Route path="/signup" element={<SignUp />}></Route>
        <Route path="/signup/modify/:member_id" element={<SignUp />}></Route>
        <Route path="/profile" element={<Profile />}></Route>
        <Route path="/club/create" element={<ClubCreate />}></Route>
        <Route path="/:club_nick/club/modify" element={<ClubCreate />}></Route>
        <Route path="/:club_nick/info" element={<ClubInfo />}></Route>
        <Route path="/:club_nick/profile" element={<Profile />}></Route>
        <Route path="/:club_nick" element={<ClubMain />}></Route>
        <Route path="/:club_nick/signup" element={<ClubSignUp />}></Route>
        <Route
          path="/:club_nick/signup/modify/:crew_id"
          element={<ClubSignUp />}
        ></Route>
        <Route path="/:club_nick/activities" element={<Activities />}></Route>
        <Route
          path="/:club_nick/activity/:activity_id"
          element={<Activity />}
        ></Route>
        <Route
          path="/:club_nick/activity/create"
          element={<ActivityCreate />}
        ></Route>
        <Route
          path="/:club_nick/activity/modify/:activity_id"
          element={<ActivityCreate />}
        ></Route>
        <Route path="/:club_nick/crews" element={<Crews />}></Route>
        <Route path="/:club_nick/crew/:crew_id" element={<Crew />}></Route>
        <Route path="/:club_nick/notices" element={<Notices />}></Route>
        <Route
          path="/:club_nick/notices/bulletin"
          element={<Bulletin />}
        ></Route>
        <Route
          path="/:club_nick/notice/:notice_id"
          element={<Notice />}
        ></Route>
        <Route
          path="/:club_nick/notice/create"
          element={<NoticeCreate />}
        ></Route>
        <Route
          path="/:club_nick/notice/modify/:notice_id"
          element={<NoticeCreate />}
        ></Route>
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
      <Footer />
    </div>
  );
}

function App() {
  return (
    <BrowserRouter
      future={{
        v7_startTransition: true, // 상태 업데이트에 React.startTransition 사용
        v7_relativeSplatPath: true, // Splat 경로의 상대 경로 처리 변경 적용
      }}
    >
      <AppContent />
    </BrowserRouter>
  );
}

export default App;
