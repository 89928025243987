import React from "react";
import { Typography, Box } from "@mui/material";

function NotFound() {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ height: "50vh" }}
    >
      <Typography variant="h6" color="error">
        Page not found 404 Error
      </Typography>
    </Box>
  );
}

export default NotFound;
