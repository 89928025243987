import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import conf from "../../config/conf_sql.json";
import ApiHeader from "../Common/ApiHeader";
import Hashids from "hashids";
const hashids = new Hashids(conf.secret, 10);
import ErrorHandler from "../Common/ErrorHandler";

function ActivityList(props) {
  const { club_nick } = useParams();
  const { adminLevel, text, api, needSearch } = props;

  useEffect(() => {
    callApi();
  }, []);

  const lst_col = ["날짜", "모임", "장소"];
  const [activityList, setActivityList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const order = props.order == "asc" ? 1 : -1;

  const callApi = async () => {
    try {
      const response = await ApiHeader.get(api);
      setActivityList(response.data);
    } catch (e) {
      ErrorHandler(e, "ActivityList.js, callApi");
    }
  };

  const handleChange = (e) => {
    const newSearchTerm = e.target.value.toLowerCase();
    setSearchTerm(newSearchTerm);
  };

  let filterSortData = activityList
    .filter(
      (row) =>
        row.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.location.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => (a.sort > b.sort ? order : -order));

  if (adminLevel < 2) {
    filterSortData = filterSortData.slice(0, 10);
  }

  // 페이징 처리를 위한 변수들을 선언합니다.
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // 페이징 처리를 위해 현재 페이지에 해당하는 데이터만 선택합니다.
  const paginatedData = filterSortData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const totalPages = Math.ceil(filterSortData.length / itemsPerPage);
  const pageNeighbours = 1; // 현재 페이지 양옆에 표시할 페이지 번호의 수
  const totalNumbers = pageNeighbours * 2 + 1; // "<<", ">>"와 현재 페이지를 포함한 표시할 번호의 총 수

  let startPage = Math.max(2, currentPage - pageNeighbours);
  let endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);

  // 페이지 번호의 수가 totalNumbers보다 작을 경우, startPage와 endPage를 조정합니다.
  if (endPage - startPage + 1 < totalNumbers) {
    if (currentPage < totalPages / 2) {
      endPage = Math.min(
        totalPages - 1,
        endPage + totalNumbers - (endPage - startPage + 1)
      );
    } else {
      startPage = Math.max(
        2,
        startPage - totalNumbers + (endPage - startPage + 1)
      );
    }
  }

  // 페이지 번호를 생성합니다.
  const pageNumbers = [];
  if (filterSortData.length > itemsPerPage) {
    pageNumbers.push(1);
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
    pageNumbers.push(totalPages);
  }

  // 페이지 변경 버튼을 눌렀을 때의 처리입니다.
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const tableData = paginatedData.map((row) => {
    return (
      <TableRow key={row.activity_id}>
        <TableCell key={"activity_" + row.activity_id}>
          <Link
            key={"link_" + row.activity_id}
            to={`/${club_nick}/activity/${hashids.encode(row.activity_id)}`}
            style={{ color: "#3f50b5", textDecoration: "inherit" }}
          >
            {row.name}
          </Link>
          <br />
          🕗
          {moment(row.activity_time)
            .subtract(conf.time_offset)
            .format("YYYY.MM.DD HH:mm")}{" "}
          <span className="small-text">
            🚩
            {row.location}
          </span>
        </TableCell>
      </TableRow>
    );
  });

  return (
    <div>
      <Toolbar key="tb">
        <Typography key="tg1" variant="h7">
          {text}
        </Typography>
        {needSearch && (
          <>
            <Typography
              key="tg2"
              noWrap
              component="div"
              sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
            />
            &ensp; <SearchIcon key="si" />
            <InputBase
              key="ib"
              type="text"
              className="search"
              onChange={handleChange}
              placeholder="Search..."
            />
          </>
        )}
      </Toolbar>
      <Table key="t">
        <TableBody key="tb_data">{tableData}</TableBody>
      </Table>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {pageNumbers.map((number, index) => (
          <Button
            key={index}
            onClick={() => number !== "..." && handlePageChange(number)}
            disabled={number === "..." || number === currentPage}
          >
            {number}
          </Button>
        ))}
      </div>
    </div>
  );
}

export default ActivityList;
