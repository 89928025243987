import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { AccountCircle, Refresh as RefreshIcon } from "@mui/icons-material";
import KakaoLoginHandle from "../Login/KakaoLoginHandle";
import KakaoLogoutHandle from "../Login/KakaoLogoutHandle";

function TopRight({ club_nick, logout }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showKakaoLogin, setShowKakaoLogin] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setShowKakaoLogin(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);
  const handleRefresh = () => window.location.reload();

  if (logout) return showKakaoLogin && <KakaoLoginHandle />;

  const menuItems = [
    {
      label: club_nick ? "Club" : "CrewWITH",
      path: club_nick ? `/${club_nick}/info` : `/crewwith`,
    },
    {
      label: "Profile",
      path: club_nick ? `/${club_nick}/profile` : `/profile`,
    },
  ];

  return (
    <div>
      <IconButton onClick={handleRefresh} color="inherit" size="small">
        <RefreshIcon />
      </IconButton>
      <IconButton size="large" onClick={handleMenuOpen} color="inherit">
        <AccountCircle />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        keepMounted
      >
        {menuItems.map(({ label, path }) => (
          <MenuItem key={label} onClick={handleMenuClose}>
            <Link
              to={path}
              style={{ color: "inherit", textDecoration: "none" }}
            >
              {label}
            </Link>
          </MenuItem>
        ))}
        <MenuItem onClick={handleMenuClose}>
          <KakaoLogoutHandle />
        </MenuItem>
      </Menu>
    </div>
  );
}

export default TopRight;
