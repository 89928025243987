import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  Box,
} from "@mui/material";
import useCrewData from "../Common/CrewData";
import moment from "moment";
import conf from "../../config/conf_sql.json";
import Linkify from "react-linkify";
import ReactPlayer from "react-player";
import ShareIcon from "@mui/icons-material/Share";
import ApiHeader from "../Common/ApiHeader";
import Hashids from "hashids";
const hashids = new Hashids(conf.secret, 10);
import ErrorHandler from "../Common/ErrorHandler";
import ClubPublicInfo from "../Club/ClubPublicInfo";
import MessageList from "../Message/MessageList";
import LikeButton from "../Common/LikeButton";

const isYoutubeUrl = (url) => {
  const youtubeRegex = /^(https?\:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/;
  return youtubeRegex.test(url);
};

const renderLink = (href, text, key) => {
  if (isYoutubeUrl(href)) {
    return <ReactPlayer key={key} url={href} controls width="100%" />;
  }
  return (
    <a href={href} key={key}>
      {text}
    </a>
  );
};

function Notice() {
  const { club_nick, notice_id } = useParams();
  const { memberId, clubId, crewId, adminLevel } = useCrewData(club_nick);

  const [noticeData, setNoticeData] = useState({});
  const [crew_id, setCrew_id] = useState("");
  const [crewNick, setCrewNick] = useState("");
  const [myNick, setMyNick] = useState("");
  const [noticeImage, setNoticeImage] = useState([]);
  const [messageCount, setMessageCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const hasSentRequest = useRef(false);

  const navigate = useNavigate();

  useEffect(() => {
    let api = `/api/v1/notices/${notice_id}`;
    if (notice_id === "regulation") {
      api = `/api/v1/notices/club_nick/${club_nick}/list/category/규정`;
    }
    ApiHeader.get(api)
      .then((response) => {
        setLoading(false);
        setNoticeData(response.data);
      })
      .catch((e) => {
        ErrorHandler(e, "Notice.js, get notice data");
      });
  }, [notice_id]);

  useEffect(() => {
    if (noticeData.crew_id) {
      ApiHeader.get(`/api/v1/crews/${hashids.encode(noticeData.crew_id)}`)
        .then((response) => {
          setCrewNick(response.data.nick);
          setCrew_id(response.data.id);
        })
        .catch((e) => {
          ErrorHandler(e, "Notice.js, get crew nick");
        });
    }
  }, [noticeData.crew_id]);

  useEffect(() => {
    if (noticeData.notice_image) {
      ApiHeader.get(`/api/v1/images/list/${noticeData.notice_image}`)
        .then((response) => {
          setNoticeImage(response.data);
        })
        .catch((e) => {
          ErrorHandler(e, "Notice.js, get crew nick");
        });
    }
  }, [noticeData.notice_image]);

  useEffect(() => {
    if (crewId === "") return;
    ApiHeader.get(`/api/v1/crews/${crewId}`)
      .then((response) => {
        if (response.data === null) return;
        setMyNick(response.data.nick);
      })
      .catch((e) => {
        ErrorHandler(e, "Notice.js, get my nick");
      });
  }, [crewId]);

  useEffect(() => {
    if (!notice_id || hasSentRequest.current) return; // 조건에 따라 실행 중단

    hasSentRequest.current = true; // API 호출 전에 플래그 설정

    ApiHeader.put(`/api/v1/notices/${notice_id}/view`)
      .then((response) => {})
      .catch((e) => {
        ErrorHandler(e, "NoticeView.js, update view count");
        hasSentRequest.current = false; // 실패 시 다시 호출 가능하게 설정
      });
  }, [notice_id]); // notice_id가 바뀔 때만 실행

  useEffect(() => {
    ApiHeader.get(`/api/v1/messages/notice/${notice_id}/count`)
      .then((response) => {
        setMessageCount(response.data.count);
      })
      .catch((e) => {
        ErrorHandler(e, "Notice.js, get message count");
      });
  }, [notice_id]);

  // 수정 component
  const modifyApi = async () => {
    const data = {
      notice_category: noticeData.notice_category,
      notice_title: noticeData.notice_title,
      notice_text: noticeData.notice_text,
      type: "put",
    };
    try {
      navigate(`/${club_nick}/notice/modify/${hashids.encode(noticeData.id)}`, {
        state: {
          ...data, // 기존 데이터 병합
          timeAuthorized: Date.now(), // timeAuthorized 추가
        },
      });
    } catch (e) {
      ErrorHandler(e, "Notice.js, modifyApi");
    }
  };

  // 삭제 component
  const [open, setOpen] = useState(false);
  const eventClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleAgree = () => {
    deleteApi();
    setOpen(false);
  };

  const deleteApi = async () => {
    try {
      await ApiHeader.put(
        `/api/v1/notices/delete/${hashids.encode(noticeData.id)}`
      );
      await ApiHeader.put(`/api/v1/images/list/${noticeData.notice_image}`);
      navigate(-1);
    } catch (e) {
      ErrorHandler(e, "Notice.js, deleteApi");
    }
  };

  // 클립보드에 복사하는 함수
  const [copySuccess, setCopySuccess] = useState("");
  const handleCopy = () => {
    const textToCopy = `[${noticeData.notice_category}] ${noticeData.notice_title}
${window.location.href}`;

    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          setCopySuccess("Copied to clipboard!");
          setTimeout(() => setCopySuccess(""), 2000); // 메시지 2초 후 사라짐
        })
        .catch((e) => {
          ErrorHandler(e, "Notice.js, handleCopy if true");
        });
    } else {
      // Fallback method for browsers that don't support navigator.clipboard
      const textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand("copy");
        setCopySuccess("Copied to clipboard!");
        setTimeout(() => setCopySuccess(""), 2000); // 메시지 2초 후 사라짐
      } catch (e) {
        ErrorHandler(e, "Notice.js, handleCopy else");
      }
      document.body.removeChild(textArea);
    }
  };

  return (
    <div>
      {!loading && adminLevel > 0 ? (
        <>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }} key="notice_title">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <span style={{ fontWeight: "normal", fontSize: "0.9em" }}>
                      [{noticeData.notice_category}]
                    </span>
                    {noticeData.notice_title}
                    <LikeButton
                      crew_id={crewId}
                      table_type={"notice"}
                      table_id={notice_id}
                      size={18}
                    />
                  </div>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell key={`${noticeData.id}-date`}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <Link
                      to={`/${club_nick}/crew/${hashids.encode(crew_id)}`}
                      style={{ color: "#3f50b5", textDecoration: "inherit" }}
                    >
                      {crewNick}
                    </Link>
                    <span className="small-text">
                      {moment(noticeData.created_time)
                        .subtract(conf.time_offset, "hours")
                        .format("YY.MM.DD(ddd) HH:mm")}{" "}
                      👁️ {noticeData.view} 💬 {messageCount}
                    </span>
                  </div>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell key="notice_text" style={{ whiteSpace: "pre-wrap" }}>
                  <Linkify componentDecorator={renderLink}>
                    {noticeData.notice_text}
                  </Linkify>
                  <br />
                  <br />
                  {noticeImage.map((image) => (
                    <img
                      key={image.id}
                      src={`${conf.media}${image.image_url}`}
                      alt={`image.id`}
                      style={{ width: "100%" }}
                    />
                  ))}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          {adminLevel >= 8 ||
          noticeData.crew_id === hashids.decode(crewId)[0] ? (
            <Stack
              direction="row"
              spacing={2}
              sx={{
                mt: 1,
                mr: 3,
                display: "flex",
                ml: "auto",
                justifyContent: "flex-end",
              }}
            >
              {" "}
              <Button onClick={handleCopy} sx={{ display: "flex", ml: "auto" }}>
                <ShareIcon />
              </Button>
              {copySuccess && (
                <div
                  style={{
                    position: "fixed",
                    bottom: "10px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    backgroundColor: "lightgreen",
                    padding: "10px",
                    borderRadius: "5px",
                  }}
                >
                  {copySuccess}
                </div>
              )}
              <Button
                variant="contained"
                onClick={modifyApi}
                sx={{ display: "flex", ml: "auto" }}
              >
                수정
              </Button>
              <Button variant="contained" onClick={eventClick} color="error">
                삭제
              </Button>
              <Dialog open={open} onClose={handleClose}>
                <DialogTitle id="alert-dialog-title">삭제 확인</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    삭제 하시겠습니까?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>취소</Button>
                  <Button onClick={handleAgree} autoFocus>
                    삭제
                  </Button>
                </DialogActions>
              </Dialog>
            </Stack>
          ) : (
            ""
          )}
          <br />
          <MessageList
            order="asc"
            api={`/api/v1/notices/${notice_id}/messages`}
            crew_id={crewId}
            club_id={clubId}
            crew_nick={myNick}
            notice_id={notice_id}
            text="Voice of Crew"
            club_nick={club_nick}
            admin_level={adminLevel}
          />
        </>
      ) : !loading ? (
        <ClubPublicInfo memberId={memberId} crewId={crewId} />
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ height: "50vh" }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
}

export default Notice;
