import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Stack,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
} from "@mui/material";
import useCrewData from "../Common/CrewData";
import moment from "moment";
import conf from "../../config/conf_sql.json";
import ActivityList from "../Activity/ActivityList";
import ApiHeader from "../Common/ApiHeader";
import Hashids from "hashids";
const hashids = new Hashids(conf.secret, 10);
import ErrorHandler from "../Common/ErrorHandler";

const Profile = () => {
  const { club_nick } = useParams();
  const [memberData, setMemberData] = useState(null);
  const [crewData, setCrewData] = useState(null);
  const [token, setToken] = useState(null);
  const { memberId, clubId, crewId, adminLevel } = useCrewData(club_nick);

  const navigate = useNavigate();

  const [userInput, setUserInput] = useState("");

  useEffect(() => {
    let token_get = localStorage.getItem("TOKEN_CREWWITH");
    if (token_get) {
      token_get = token_get.replace(/"/g, "");
    }
    setToken(token_get);
  }, [memberData, crewData]);

  useEffect(() => {
    if (memberId && token) {
      ApiHeader.get(`/api/v1/members/${memberId}/decrypt/${token}`)
        .then((response) => {
          if (response.data) {
            setMemberData(response.data);
          }
        })
        .catch((e) => {
          ErrorHandler(e, "Profile.js, get member data");
        });
    }
  }, [memberId, token]);

  useEffect(() => {
    if (crewId) {
      ApiHeader.get(`/api/v1/crews/${crewId}`)
        .then((response) => {
          if (response.data) {
            setCrewData(response.data);
          }
        })
        .catch((e) => {
          ErrorHandler(e, "Profile.js, get crew data");
        });
    }
  }, [crewId]);

  const modifyMemberApi = async () => {
    try {
      navigate(`/signup/modify/${memberId}`, {
        state: { timeAuthorized: Date.now() },
      });
    } catch (e) {
      ErrorHandler(e, "Profile.js, modify member");
    }
  };

  const deleteMemberApi = async () => {
    ApiHeader.put(`/api/v1/members/${memberId}/state/0`)
      .then((response) => {})
      .catch((e) => {
        ErrorHandler(e, "Profile.js, delete member, member state 0");
      });
    ApiHeader.put(`/api/v1/members/${memberId}/crews/state/0`)
      .then((response) => {})
      .catch((e) => {
        ErrorHandler(e, "Profile.js, delete member, member crew state 0");
      });
    ApiHeader.delete(`/api/v1/attendees/member/${memberId}`)
      .then((response) => {})
      .catch((e) => {
        ErrorHandler(e, "Profile.js, delete member, member attendee");
      });
    ApiHeader.put(`/api/v1/members/${memberId}/crews/admin/0`)
      .then((response) => {
        navigate(`/`);
        window.location.reload();
      })
      .catch((e) => {
        ErrorHandler(e, "Profile.js, delete member, member crew admin 0");
      });
  };

  const [openMember, setOpenMember] = React.useState(false);
  const eventMemberClick = () => {
    setOpenMember(true);
  };
  const memberClose = () => {
    setOpenMember(false);
  };
  const memberAgree = () => {
    deleteMemberApi();
    setOpenMember(false);
  };

  const modifyCrewApi = async () => {
    try {
      navigate(`/${club_nick}/signup/modify/${crewId}`, {
        state: { timeAuthorized: Date.now() },
      });
    } catch (e) {
      ErrorHandler(e, "Profile.js, modify crew");
    }
  };

  const deleteCrewApi = async () => {
    ApiHeader.put(`/api/v1/crews/${crewId}/state/0`)
      .then((response) => {})
      .catch((e) => {
        ErrorHandler(e, "Profile.js, delete crew, crew state 0");
      });
    ApiHeader.delete(`/api/v1/attendees/crew/${crewId}`)
      .then((response) => {})
      .catch((e) => {
        ErrorHandler(e, "Profile.js, delete crew, crew attendee");
      });
    ApiHeader.put(`/api/v1/crews/${crewId}/admin/0`)
      .then((response) => {
        navigate(`/${club_nick}`);
        window.location.reload();
      })
      .catch((e) => {
        ErrorHandler(e, "Profile.js, delete crew, crew state 0");
      });
  };

  const [openCrew, setOpenCrew] = React.useState(false);
  const eventCrewClick = () => {
    setOpenCrew(true);
  };
  const crewClose = () => {
    setOpenCrew(false);
  };
  const crewAgree = () => {
    deleteCrewApi();
    setOpenCrew(false);
  };

  return (
    <div>
      {memberData &&
        memberId !== "" &&
        memberData.id == hashids.decode(memberId)[0] && (
          <>
            <Typography variant="h6" sx={{ margin: 2 }}>
              Member Profile
            </Typography>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>이름</TableCell>
                  <TableCell>
                    {`${memberData.name} (${
                      memberData.gender === "Male" ? "남" : "여"
                    }, ${memberData.nationality})`}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>생일</TableCell>
                  <TableCell>
                    {moment(memberData.birthday).format("Y년 M월 D일")}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>사는곳</TableCell>
                  <TableCell>{memberData.address}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>이메일</TableCell>
                  <TableCell>{memberData.email}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>연락처</TableCell>
                  <TableCell>{memberData.contact}</TableCell>
                </TableRow>
                {memberData.info &&
                  Object.entries(JSON.parse(memberData.info)).map(
                    ([key, value]) => (
                      <TableRow key={key}>
                        <TableCell>{key}</TableCell>
                        <TableCell style={{ whiteSpace: "pre-wrap" }}>
                          {value}
                        </TableCell>
                      </TableRow>
                    )
                  )}
                <TableRow>
                  <TableCell>가입일</TableCell>
                  <TableCell>
                    {moment(memberData.created_time)
                      .subtract(conf.time_offset)
                      .format("Y년 M월 D일")}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Stack
              direction="row"
              spacing={2}
              sx={{
                mt: 1,
                mr: 3,
                display: "flex",
                ml: "auto",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                onClick={modifyMemberApi}
                sx={{ display: "flex", ml: "auto" }}
              >
                회원 정보 수정
              </Button>
              <Button
                variant="contained"
                onClick={eventMemberClick}
                color="error"
                sx={{ display: "flex", ml: "auto" }}
              >
                회원 탈퇴
              </Button>
              <Dialog open={openMember} onClose={memberClose}>
                <DialogTitle id="alert-dialog-title">
                  회원 탈퇴 확인
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    회원 탈퇴를 진행하려면 "회원 탈퇴 하겠습니다."라고
                    입력해주세요.
                  </DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="회원 탈퇴 확인"
                    type="text"
                    fullWidth
                    size="small"
                    value={userInput}
                    onChange={(e) => setUserInput(e.target.value)}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={memberClose}>취소</Button>
                  <Button
                    onClick={() => {
                      if (userInput === "회원 탈퇴 하겠습니다.") {
                        memberAgree();
                      } else {
                        alert("정확한 문구를 입력해주세요.");
                      }
                    }}
                    autoFocus
                  >
                    탈퇴
                  </Button>
                </DialogActions>
              </Dialog>
            </Stack>
          </>
        )}
      {crewData &&
        crewId !== "" &&
        crewData.id == hashids.decode(crewId)[0] && (
          <>
            <Typography variant="h6" sx={{ margin: 2 }}>
              {club_nick.toUpperCase()} Crew Profile
            </Typography>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>이름</TableCell>
                  <TableCell>{crewData.nick}</TableCell>
                </TableRow>
                {crewData.info &&
                  Object.entries(JSON.parse(crewData.info)).map(
                    ([key, value]) => (
                      <TableRow key={key}>
                        <TableCell>{key}</TableCell>
                        <TableCell style={{ whiteSpace: "pre-wrap" }}>
                          {value}
                        </TableCell>
                      </TableRow>
                    )
                  )}
                <TableRow>
                  <TableCell>크루 레벨</TableCell>
                  <TableCell>{adminLevel}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Stack
              direction="row"
              spacing={2}
              sx={{
                mt: 1,
                mr: 3,
                display: "flex",
                ml: "auto",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                onClick={modifyCrewApi}
                sx={{ display: "flex", ml: "auto" }}
              >
                {club_nick.toUpperCase()} 회원 정보 수정
              </Button>
              <Button
                variant="contained"
                onClick={eventCrewClick}
                color="error"
                sx={{ display: "flex", ml: "auto" }}
              >
                {club_nick.toUpperCase()} 회원 탈퇴
              </Button>
              <Dialog open={openCrew} onClose={crewClose}>
                <DialogTitle id="alert-dialog-title">
                  {club_nick.toUpperCase()} 회원 탈퇴 확인
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    회원 탈퇴를 진행하려면 "회원 탈퇴 하겠습니다."라고
                    입력해주세요.
                  </DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="회원 탈퇴 확인"
                    type="text"
                    fullWidth
                    size="small"
                    value={userInput}
                    onChange={(e) => setUserInput(e.target.value)}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={crewClose}>취소</Button>
                  <Button
                    onClick={() => {
                      if (userInput === "회원 탈퇴 하겠습니다.") {
                        crewAgree();
                      } else {
                        alert("정확한 문구를 입력해주세요.");
                      }
                    }}
                    autoFocus
                  >
                    탈퇴
                  </Button>
                </DialogActions>
              </Dialog>
            </Stack>
            <ActivityList
              order="desc"
              api={`/api/v1/crews/${crewId}/activities`}
              text="Participatory Activities"
              needSearch={true}
            />
          </>
        )}
    </div>
  );
};

export default Profile;
