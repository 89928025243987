import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import NoticeList from "../Notice/NoticeList";
import useCrewData from "../Common/CrewData";
import ClubPublicInfo from "../Club/ClubPublicInfo";

function Notices() {
  const { club_nick } = useParams();
  const { memberId, clubId, crewId, adminLevel } = useCrewData(club_nick);

  return (
    <div>
      {adminLevel > 0 && (
        <>
          <NoticeList
            order="desc"
            text="Regulation"
            api={`/api/v1/notices/club_nick/${club_nick}/list/category/규정`}
            club_nick={club_nick}
            crew_id={crewId}
            club_id={clubId}
            crew_nick={crewId}
            admin_level={adminLevel}
          />
          <NoticeList
            order="desc"
            text="Notices"
            api={`/api/v1/notices/club_nick/${club_nick}/list/category/공지`}
            club_nick={club_nick}
            crew_id={crewId}
            club_id={clubId}
            crew_nick={crewId}
            admin_level={adminLevel}
            needSearch={true}
          />
          {adminLevel >= 7 && (
            <Button
              variant="contained"
              sx={{ mt: 1, mr: 3, display: "flex", ml: "auto" }}
            >
              <Link
                to={`/${club_nick}/notice/create`}
                state={{ timeAuthorized: Date.now() }}
                style={{ color: "inherit", textDecoration: "inherit" }}
              >
                공지 추가
              </Link>
            </Button>
          )}
        </>
      )}
      <ClubPublicInfo memberId={memberId} crewId={crewId} />
    </div>
  );
}

export default Notices;
