import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  FormControl,
  FormLabel,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Select,
  MenuItem,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import moment from "moment";
import conf from "../../config/conf_sql.json";
import useCrewData from "../Common/CrewData";
import ApiHeader from "../Common/ApiHeader";
import Hashids from "hashids";
import ErrorHandler from "../Common/ErrorHandler";

const hashids = new Hashids(conf.secret, 10);

function ActivityCreate() {
  const { club_nick, activity_id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { memberId, clubId, crewId, adminLevel } = useCrewData(club_nick);
  const {
    name = "",
    max_crew = 40,
    attend_level = 1,
    activity_time = null,
    location: loc = "",
    location_url = "",
    subgroup = "",
    info = "",
    type = "",
    state = "",
    timeAuthorized = 0,
  } = location.state || {};

  const formattedDate = activity_time
    ? moment(activity_time).subtract(conf.time_offset).format("YYYY-MM-DD")
    : "";
  const formattedTime = activity_time
    ? moment(activity_time).subtract(conf.time_offset).format("HH:mm")
    : "";

  const [vName, setName] = useState(name);
  const [vMaximum, setMaximum] = useState(max_crew);
  const [vLevel, setLevel] = useState(attend_level);
  const [vDate, setDate] = useState(formattedDate);
  const [vTime, setTime] = useState(formattedTime);
  const [vLocation, setLocation] = useState(loc);
  const [vUrl, setUrl] = useState(location_url);
  const [vSubgroup, setSubgroup] = useState(subgroup);
  const [vInfo, setInfo] = useState(info);
  const [vState, setState] = useState(state);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [clubLevels, setClubLevels] = useState({});

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [isOK, setIsOK] = useState(false);

  useEffect(() => {
    setIsAuthorized(Date.now() - timeAuthorized <= 10000);
  }, [timeAuthorized]);

  useEffect(() => {
    if (!clubId) return;
    ApiHeader.get(`/api/v1/clubs/${clubId}`)
      .then((res) => {
        const levels = res.data.levels ? JSON.parse(res.data.levels) : {};
        setClubLevels(levels);
        if (!activity_id && adminLevel === 5) {
          setLevel(5);
        }
      })
      .catch((e) => ErrorHandler(e, "ActivityCreate > club level fetch"));
  }, [clubId]);

  useEffect(() => {
    if (!activity_id) {
      const storedDate = sessionStorage.getItem("selectedDate");
      const storedTimestamp = sessionStorage.getItem("selectedDateTimestamp");
      const now = new Date().getTime();
      const yesterday = moment().subtract(1, "days").startOf("day");

      if (storedDate && storedTimestamp && now - storedTimestamp < 60000) {
        const date = moment(new Date(storedDate));
        if (date.isAfter(yesterday)) setDate(date.format("YYYY-MM-DD"));
      }
    }
  }, [activity_id]);

  useEffect(() => {
    if (adminLevel && !vState) setState(adminLevel >= 6 ? 2 : 1);
  }, [adminLevel]);

  const handleSave = async () => {
    const dateTime = moment(`${vDate} ${vTime}:00`).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    try {
      if (type === "put") {
        await ApiHeader.put(`/api/v1/activities/${activity_id}`, {
          activity_time: dateTime,
          name: vName,
          max_crew: vMaximum,
          attend_level: vLevel,
          location: vLocation,
          location_url: vUrl,
          subgroup: vSubgroup,
          info: vInfo,
          state: vState,
        });
        navigate(`/${club_nick}/activity/${activity_id}`);
      } else {
        const res = await ApiHeader.get("/api/v1/activities/max_id");
        const newId = res.data[0].max_id + 1;
        await ApiHeader.post("/api/v1/activities", {
          id: newId,
          club_id: hashids.decode(clubId)[0],
          crew_id: hashids.decode(crewId)[0],
          activity_time: dateTime,
          name: vName,
          max_crew: vMaximum,
          location: vLocation,
          location_url: vUrl,
          subgroup: vSubgroup,
          info: vInfo,
          attend_level: vLevel,
          state: vState,
        });
        await ApiHeader.post("/api/v1/attendees", {
          crew_id: hashids.decode(crewId)[0],
          activity_id: newId,
          subgroup: "리더",
          state: 1,
        });
        navigate(`/${club_nick}/activity/${hashids.encode(newId)}`);
      }
    } catch (e) {
      ErrorHandler(e, "ActivityCreate.js > handleSave");
    }
  };

  const handleSubmit = () => {
    const future = moment(`${vDate} ${vTime}`).isAfter(moment());
    if (
      vName &&
      vMaximum &&
      vLevel &&
      vDate &&
      vTime &&
      vLocation &&
      vState &&
      future
    ) {
      setMessage(
        activity_id ? "모임 수정 하시겠습니까?" : "모임 등록 하시겠습니까?"
      );
      setIsOK(true);
    } else {
      setMessage(
        !future
          ? "과거 시간은 등록할 수 없습니다."
          : "모두 작성해 주시기 바랍니다."
      );
      setIsOK(false);
    }
    setOpen(true);
  };

  return isAuthorized ? (
    <Box sx={{ maxWidth: 800, margin: 1 }}>
      <FormControl fullWidth>
        <FormLabel sx={{ fontSize: 32 }}>
          {activity_id ? "모임 수정" : "모임 등록"}
        </FormLabel>

        <FormLabel sx={{ fontSize: 16, mt: 2 }}>모임 이름</FormLabel>
        <TextField
          value={vName}
          onChange={(e) => setName(e.target.value)}
          size="small"
          required
        />

        <FormLabel sx={{ fontSize: 16, mt: 2 }}>모임 정원</FormLabel>
        <TextField
          value={vMaximum}
          onChange={(e) => setMaximum(e.target.value)}
          size="small"
          required
        />

        <FormLabel sx={{ fontSize: 16, mt: 2 }}>모임 유형</FormLabel>
        <RadioGroup
          row
          value={String(vState)}
          onChange={(e) => setState(e.target.value)}
        >
          <FormControlLabel
            value="2"
            control={<Radio size="small" />}
            label="정규"
            disabled={adminLevel <= 5}
          />
          <FormControlLabel
            value="1"
            control={<Radio size="small" />}
            label="비정규"
          />
        </RadioGroup>

        <FormLabel sx={{ fontSize: 16, mt: 2 }}>모임 참석 등급</FormLabel>
        <Select
          value={clubLevels[vLevel] ? vLevel : ""}
          onChange={(e) => setLevel(e.target.value)}
          size="small"
          disabled={adminLevel <= 5}
        >
          {Object.entries(clubLevels).map(
            ([key, value]) =>
              value && (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              )
          )}
        </Select>

        <FormLabel sx={{ fontSize: 16, mt: 2 }}>모임 시간</FormLabel>
        <TextField
          type="date"
          value={vDate}
          onChange={(e) => setDate(e.target.value)}
          size="small"
          required
        />
        <TextField
          type="time"
          value={vTime}
          onChange={(e) => setTime(e.target.value)}
          size="small"
          required
        />

        <FormLabel sx={{ fontSize: 16, mt: 2 }}>모임 장소</FormLabel>
        <TextField
          value={vLocation}
          onChange={(e) => setLocation(e.target.value)}
          size="small"
          required
        />
        <TextField
          value={vUrl}
          onChange={(e) => setUrl(e.target.value)}
          label="Location URL"
          size="small"
        />

        <FormLabel sx={{ fontSize: 16, mt: 2 }}>모임 소그룹</FormLabel>
        <TextField
          multiline
          value={vSubgroup}
          onChange={(e) => setSubgroup(e.target.value)}
          size="small"
        />

        <FormLabel sx={{ fontSize: 16, mt: 2 }}>모임 정보</FormLabel>
        <TextField
          multiline
          value={vInfo}
          onChange={(e) => setInfo(e.target.value)}
          size="small"
        />

        <Button variant="contained" onClick={handleSubmit} sx={{ mt: 3 }}>
          {activity_id ? "수정" : "등록"}
        </Button>

        <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogTitle>
            {activity_id ? "모임 수정 확인" : "모임 등록 확인"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>{message}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)}>취소</Button>
            <Button
              onClick={isOK ? handleSave : () => setOpen(false)}
              autoFocus
            >
              확인
            </Button>
          </DialogActions>
        </Dialog>
      </FormControl>
    </Box>
  ) : (
    <div>잘못된 접근입니다.</div>
  );
}

export default ActivityCreate;
