import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Paper, Typography, Box } from "@mui/material";
import conf from "../../config/conf_sql.json";
import ApiHeader from "../Common/ApiHeader";
import ErrorHandler from "../Common/ErrorHandler";

function ClubList({ memberId }) {
  const [myClub, setMyClub] = useState([]);
  const [allClub, setAllClub] = useState([]);

  useEffect(() => {
    if (memberId !== "") {
      ApiHeader.get(`/api/v1/members/${memberId}/clubs`)
        .then((response) => {
          setMyClub(response.data);
        })
        .catch((e) => {
          ErrorHandler(e, "Main.js, get my club data");
        });

      ApiHeader.get(`/api/v1/clubs/list`)
        .then((response) => {
          setAllClub(response.data);
        })
        .catch((e) => {
          ErrorHandler(e, "Main.js, get all club data");
        });
    }
  }, [memberId]);

  const renderLogos = () => {
    if (myClub.length === 1) {
      return (
        <Link
          to={"/" + myClub[0].nick}
          style={{
            color: "inherit",
            textDecoration: "inherit",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            sx={{
              width: "100%",
              height: "auto",
              aspectRatio: "1 / 1",
              objectFit: "contain",
              maxWidth: "310px",
            }}
            alt={myClub[0].nick}
            src={conf.media + JSON.parse(myClub[0].setting).icon_url}
          />
        </Link>
      );
    } else if (myClub.length > 1) {
      return myClub.map((club) => {
        return (
          <Link
            key={club.nick}
            to={`/${club.nick}`}
            style={{
              color: "inherit",
              textDecoration: "inherit",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexBasis: "45%",
              flexGrow: 1,
              margin: "0 10px",
            }}
          >
            <Box
              component="img"
              sx={{
                width: "100%",
                height: "auto",
                aspectRatio: "1 / 1",
                objectFit: "contain",
              }}
              alt={club.nick}
              src={conf.media + JSON.parse(club.setting).icon_url}
            />
          </Link>
        );
      });
    } else {
      return allClub.map((club) => {
        return (
          <Link
            key={club.nick}
            to={`/${club.nick}`}
            style={{
              color: "inherit",
              textDecoration: "inherit",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexBasis: "45%",
              flexGrow: 1,
              margin: "0 10px",
            }}
          >
            <Box
              component="img"
              sx={{
                width: "100%",
                height: "auto",
                aspectRatio: "1 / 1",
                objectFit: "contain",
              }}
              alt={club.nick}
              src={conf.media + JSON.parse(club.setting).icon_url}
            />
          </Link>
        );
      });
    }
  };

  return (
    <>
      <Typography key="tg_mine" variant="h6" sx={{ mt: 2, ml: 2 }}>
        {myClub.length > 0 ? "My Club" : "Select Club"}
      </Typography>
      <div
        className="MainImage"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <Paper
          key="image"
          elevation={0}
          sx={{
            backgroundColor: "transparent",
            display: "flex",
            justifyContent: myClub.length === 1 ? "center" : "space-between",
            alignItems: "center",
            width: "100%",
            maxWidth: "700px",
          }}
        >
          {renderLogos()}
        </Paper>
      </div>
    </>
  );
}

export default ClubList;
