import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import ApiHeader from "../Common/ApiHeader";
import ErrorHandler from "../Common/ErrorHandler";
import moment from "moment";
import conf from "../../config/conf_sql.json";
import Hashids from "hashids";
const hashids = new Hashids(conf.secret, 10);

const NoShowWarning = ({ club_nick, crewId }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [activity, setActivity] = useState([]);
  const [hideForDay, setHideForDay] = useState(false);
  const [count, setCount] = useState(0);

  useEffect(() => {
    const lastHiddenTime = localStorage.getItem(
      club_nick.toUpperCase() + "_hideNoShowWarningUntil"
    );
    const now = moment();
    if (lastHiddenTime && now.isBefore(moment(lastHiddenTime))) {
      return;
    }

    const checkNoShow = async () => {
      if (!crewId) return;
      try {
        const response = await ApiHeader.get(`/api/v1/crews/${crewId}/noshow`);
        if (response.data.length > 0) {
          setCount(response.data.length);
          const recentActivity = response.data.filter((activity) =>
            moment(activity.activity_time)
              .subtract(conf.time_offset)
              .isAfter(moment().subtract(7 * response.data.length, "days"))
          );
          if (recentActivity.length > 0) {
            setActivity(recentActivity);
            setDialogOpen(true);
          }
        }
      } catch (e) {
        ErrorHandler(e, "NoShowWarning.js, checkNoShow");
      }
    };

    checkNoShow();
  }, [crewId]);

  const handleClose = () => {
    if (hideForDay) {
      localStorage.setItem(
        club_nick.toUpperCase() + "_hideNoShowWarningUntil",
        moment().subtract(conf.time_offset).add(1, "days").toISOString()
      );
    }
    setDialogOpen(false);
  };

  const handleCheckboxChange = (event) => {
    setHideForDay(event.target.checked);
  };

  return (
    <div>
      {(activity.length > 0 || dialogOpen) && (
        <Dialog open={dialogOpen} onClose={handleClose}>
          <DialogTitle>No-show Warning</DialogTitle>
          <DialogContent>
            <Typography sx={{ mb: 2 }}>
              최근 노쇼 목록은 다음과 같습니다
            </Typography>
            {activity.map((act, index) => (
              <Link
                key={index}
                to={`/${club_nick}/activity/${hashids.encode(act.activity_id)}`}
                style={{ color: "#3f50b5", textDecoration: "inherit" }}
              >
                <Typography key={index} sx={{ mt: 1 }}>
                  {moment(act.activity_time)
                    .subtract(conf.time_offset)
                    .format("YYYY-MM-DD HH:mm")}
                  <br />
                  {act.name}
                </Typography>
              </Link>
            ))}
            <Typography sx={{ mt: 2, mb: 2 }}>
              {moment(activity[0].activity_time)
                .subtract(conf.time_offset)
                .add(7 * count, "days")
                .format("YYYY-MM-DD HH:mm")}{" "}
              까지 모임 참여 및 신청이 제한됩니다.
            </Typography>
            <Typography sx={{ mt: 2, mb: 2 }}>
              기존에 신청한 모든 모임은 자동 취소되오니 노쇼하지 않도록 주의
              부탁드립니다.
            </Typography>
            {club_nick.toUpperCase() === "YRC" && (
              <Link
                key="noshownoticelink"
                to={`/YRC/notice/PoAxJ8yOkV`}
                style={{ color: "#3f50b5", textDecoration: "inherit" }}
              >
                <Typography key="noshownoticetext" sx={{ mt: 1 }}>
                  ⛔ 노쇼 관련 안내 (제한 해제 방법) ⛔
                </Typography>
              </Link>
            )}
            <FormControlLabel
              control={
                <Checkbox
                  checked={hideForDay}
                  onChange={handleCheckboxChange}
                />
              }
              label="이 창을 숨기기"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default NoShowWarning;
